.seeMoreExpanded {
  position: absolute;
  top: 100%;
  /* // left: 0;
    // bottom: 0; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* z-index: 99999 */
  transition: top 0.75s ease-out;
}

.seeMoreStyle {
  top: 0;
  left: 0;
  transition: top 0.75s ease-out;
}

.image-div {
  width: 100%;
}

/* .image-div:nth-child(1) {
  text-align: center;
  width: 100%;
} */

.video-logo {
  position: absolute;
  bottom: 20px;
  right: 70px;
  z-index: 9999;
}

.video-logo-into {
  position: absolute;
  top: 2%;
  right: 20px;
  z-index: 9999;
}

.video-logo-into img {
  width: 70px;
}

.video-logo img {
  width: 60px;
  height: 60px;
  object-fit: scale-down;
}

.content-header {
  color: #000;
  background: #fff;
  /* backdrop-filter: blur(15px); */
  /* -webkit-backdrop-filter: blur(15px); */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 0 0 10% 10%; */
  font-weight: 600;
  min-height: 10vh;
  position: absolute;
  font-size: 20px;
  text-align: center;
  width: 100%;
  top: 0px;
  left: 0px;
  -webkit-animation: slideDown 1500ms ease-out forwards;
          animation: slideDown 1500ms ease-out forwards;
  z-index: 999;
}
@-webkit-keyframes slideDown {
  0% {
    top: -18px;
  }
  100% {
    top: 20px;
  }
}
@keyframes slideDown {
  0% {
    top: -18px;
  }
  100% {
    top: 20px;
  }
}

@media screen and (min-width: 780px) {
  .storyContent {
    width: auto;
    max-width: 70%;
    max-height: 70%;
    margin: auto;
  }
  .video-logo {
    right: 50px;
  }
}

@media screen and (max-width: 770px) {
  .storyContent {
    width: auto;
    max-width: 75%;
    max-height: 75%;
    margin: auto;
  }
  .video-logo {
    right: 30px;
  }
}

@media screen and (max-width: 430px) {
  .storyContent {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
  .video-logo {
    right: 20px;
  }
}
